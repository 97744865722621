#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

/* Slider.css */
.slider-container {
  background-color: rgba(0, 0, 0, 0.7); /* Fallback for older browsers */
}

.bangla-text {
  font-family: "Hind Siliguri", "Noto Sans Bengali", sans-serif;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for better readability */
}




.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

}

.parallax-bg {
  background-image: url('../../../images/banner-hero.png');
  position: absolute;
  left: 0;
  top: 0;
  width: 150%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 500;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 16px;
  line-height: 1.3;
  padding: 15px;
  background: #ffffff2a;

}

/* Overlay for green shadow */
.bg-green-overlay {
  background-color: #1c2d0df0; /* Set the green color */
  opacity: 0.4; /* Set the transparency */
  z-index: 5; /* Ensure it's above the image but below the text */
  
}
